import React from 'react'
import { t } from 'ttag'
import { ThemeContextConsumer } from '../../ThemeContext'
import { Overlay } from '@react-md/overlay'
import { connect } from 'react-redux'
import { actionCloseModal } from '../../../actions/modal'
import {
  Contents,
  Caption,
  Modal,
  SubmitButton,
  Paragraph,
  ButtonContainer,
} from './styled'
import { formatCurrency } from '../../selectors/currency'
import { IChallengeDashboardData } from '../../Dashboard/interfaces'
import moment from 'moment'
import { actionCashOut } from '../../../actions/wallets'
import { IClosedTrade } from '../../../core/interfaces/trades'

interface ICashOut {
  activeWallet: any
  isMobile: any
  challenge: IChallengeDashboardData
  closedTrades: IClosedTrade[]
  formatCurrency: (value: any) => string
  actionCashOut: (data: any) => void
  actionCloseModal: () => void
}

function getMinDaysFromNow(startTime: any) {
  return Math.abs(
    moment(startTime).startOf('day').diff(moment().startOf('day'), 'days')
  )
}

function isSamePeriod(startDate: any, type: any) {
  const start = moment.utc(startDate)
  const current = moment().utc()

  switch (type) {
    case 'Day':
      return start.isSame(current, 'day')
    case 'Week':
      return start.isoWeek() === current.isoWeek()
    case '2 Weeks':
      return (
        Math.floor((start.isoWeek() - 1) / 2) ===
        Math.floor((current.isoWeek() - 1) / 2)
      )
    case 'Month':
      return start.month() === current.month()
    case 'Year':
      return start.year() === current.year()
    default:
      return false
  }
}

function renderFrequencyMessage(value: any) {
  switch (value) {
    case 'Day':
      return 'One Day'
    case 'Week':
      return 'One Week'
    case '2 Weeks':
      return 'Two Weeks'
    case 'Month':
      return 'One Month'
    case 'Year':
      return 'One Year'
    default:
      return ''
  }
}

function getCashOutValidationMessages({
  closedTrades,
  challenge,
  activeWallet,
  formatCurrency,
}: Pick<
  ICashOut,
  'closedTrades' | 'challenge' | 'activeWallet' | 'formatCurrency'
>) {
  const messages: string[] = []

  if (closedTrades?.length < challenge?.state?.cashoutMinTrades) {
    messages.push(
      t`Minimum number of trades not met ${challenge.state.cashoutMinTrades})`
    )
  }

  if (
    getMinDaysFromNow(challenge.state.startTime) <
    challenge?.state?.cashoutMinDays
  ) {
    messages.push(
      t`Minimum number of days not met  ${challenge.state.cashoutMinDays}`
    )
  }

  const cashoutAmount =
    activeWallet?.availableCash - challenge?.state?.initialBalance
  if (cashoutAmount < challenge?.state?.cashoutMinAmount) {
    messages.push(
      t`Minimum amount for Cash-Out not met ${formatCurrency(
        Number(challenge.state.cashoutMinAmount)
      )}`
    )
  }

  if (
    challenge?.state?.lastCashout &&
    isSamePeriod(
      challenge?.state?.lastCashout,
      challenge.state.cashoutFrequency
    )
  ) {
    messages.push(
      t`Minimum time between Cash-Out not met ${renderFrequencyMessage(
        challenge.state.cashoutFrequency
      )}`
    )
  }

  return messages
}

const CashOut = (props: ICashOut) => {
  const { closedTrades, challenge, activeWallet, formatCurrency } = props
  const [isLoading, setIsLoading] = React.useState(true)

  const cashoutAmount =
    Math.abs(activeWallet?.availableCash - challenge?.state?.initialBalance) ||
    0

  const validationMessages = getCashOutValidationMessages({
    closedTrades,
    challenge,
    activeWallet,
    formatCurrency,
  })

  const isConditionMatched = validationMessages.length === 0
  const cash = formatCurrency(cashoutAmount)

  const handleCashOut = () => {
    if (activeWallet.challengeID) {
      props.actionCashOut({
        currentChallengeId: activeWallet.challengeID,
        amount: cashoutAmount,
      })
    }
  }

  const handleClose = () => {
    setIsLoading(false)
    props.actionCloseModal()
  }

  React.useEffect(() => {
    if (closedTrades && challenge && activeWallet) {
      const validationMessages = getCashOutValidationMessages({
        closedTrades,
        challenge,
        activeWallet,
        formatCurrency,
      })

      if (isLoading && validationMessages.length >= 0) {
        setIsLoading(false)
      }
    }
  }, [closedTrades, challenge, activeWallet, formatCurrency, isLoading])

  return (
    <>
      <Modal isMobile={props.isMobile}>
        <div>
          <ThemeContextConsumer>
            {(context) => (
              <Contents backgroundColor={context.modalBackground}>
                {isLoading ? (
                  <Caption color={context.primaryText}>
                    {t`Validating Cash-Out conditions...`}
                  </Caption>
                ) : !isConditionMatched ? (
                  <>
                    <Caption color={context.primaryText}>
                      {t`Cannot continue with Cash-Out`}
                    </Caption>
                    <Paragraph colors={context}>
                      {t`Reason:`}
                      {validationMessages.map((msg, i) => (
                        <React.Fragment key={i}>
                          <br />
                          {msg}
                        </React.Fragment>
                      ))}
                    </Paragraph>
                    <SubmitButton colors={context} onClick={handleClose}>
                      {t`Close`}
                    </SubmitButton>
                  </>
                ) : (
                  <>
                    <Caption color={context.primaryText}>
                      {t`You are requesting a Cash-Out of ${cash}`}
                    </Caption>
                    <Paragraph colors={context}>
                      {t`This Cash-Out needs to be approved by the administrator.`}
                      <br />
                      {t`All your accounts will be frozen until approval or rejection of Cash-Out.`}
                    </Paragraph>
                    <Paragraph colors={context}>
                      {t`Do you wish to proceed?`}
                    </Paragraph>
                    <ButtonContainer>
                      <SubmitButton colors={context} onClick={handleClose}>
                        {t`No`}
                      </SubmitButton>
                      <SubmitButton
                        yesButton
                        colors={context}
                        onClick={handleCashOut}
                      >
                        {t`Yes`}
                      </SubmitButton>
                    </ButtonContainer>
                  </>
                )}
              </Contents>
            )}
          </ThemeContextConsumer>
        </div>
      </Modal>
      <Overlay
        id="modal-overlay"
        visible
        onRequestClose={props.actionCloseModal}
        style={{ zIndex: 40 }}
      />
    </>
  )
}

const mapStateToProps = (state: any) => ({
  activeWallet: state.wallets.activeWallet,
  closedTrades: state.trades.closed,
  challenge: state.wallets.challengeDashboard,
  formatCurrency: formatCurrency(state),
  isMobile: state.registry.isMobile,
})

export default connect(mapStateToProps, {
  actionCloseModal,
  actionCashOut,
})(CashOut)
