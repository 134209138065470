// @ts-nocheck 7057
/**
 * Adjust timer each second
 * Check if game is in dead period and try to refresh it
 */
import {
  all,
  takeLatest,
  select,
  call,
  put,
  takeEvery,
} from 'redux-saga/effects'
import {
  CASH_OUT,
  SET_ACTIVE_WALLET,
  SET_WALLETS,
  actionSetChallengeDashboard,
} from '../actions/wallets'
import { onInstrumentSelect } from './tradingSaga'
import { onFetchTrades, onFetchWalletDetails } from './registrySaga'
import { api } from '../core/createAPI'
import { actionCloseModal } from '../actions/modal'
import { onFetchUserInfo } from './accountSaga'
import {
  actionShowNotification,
  NotificationTypes,
} from '../actions/notifications'

function* onSetActiveWallet() {
  const state = yield select((state) => state)
  const selectedInstrument = state.trading.selected
  yield call(onInstrumentSelect, { payload: selectedInstrument })
  yield call(onFetchTrades)
  const activeWallet = state.wallets.activeWallet
  if (activeWallet.challengeID) {
    const resp = yield call(
      api.fetchChallengeDashboard,
      activeWallet.challengeID
    )
    const { success, challenge } = resp
    if (success) {
      yield put(actionSetChallengeDashboard(challenge))
    }
  }
}

function* onRefreshAccountData() {
  yield all([
    yield onFetchUserInfo(),
    yield onFetchWalletDetails(false),
    yield onSetActiveWallet(),
  ])
}

function* onCashoutRequest(action: any) {
  console.log('in on freeze wallet', action)

  try {
    const response = yield call(api.cashoutRequest, action.payload)
    // console.log('response', response)
    yield put(actionCloseModal())
    if (response.status === 'success') {
      yield onRefreshAccountData()
      yield put(
        actionShowNotification(NotificationTypes.GLOBAL, {
          message: 'Cashout Request Submitted',
          success: true,
        })
      )
    } else {
      yield put(
        actionShowNotification(NotificationTypes.GLOBAL, {
          message: response.error || 'Error',
          success: false,
        })
      )
    }
  } catch (err) {
    console.warn(err)
  }
}

function* walletSaga() {
  yield takeLatest(SET_WALLETS, onSetActiveWallet)
  yield takeLatest(SET_ACTIVE_WALLET, onSetActiveWallet)
  yield takeLatest(CASH_OUT, onCashoutRequest)
}

export default walletSaga
