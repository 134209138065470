/**
 * Implements a header bar containing brand logo, time, language picker, user sign in & menu
 */
import React, { useCallback, useState } from 'react'
import { connect } from 'react-redux'
import Account from './Account'
import Time from './Time'
import LanguageSelect from './LanguageSelect'
import { IRegistry } from '../../core/API'
import { isLoggedIn } from '../selectors/loggedIn'
import { actionGuestDemoRequest } from '../../actions/account'
import { ThemeContextConsumer } from '../ThemeContext'
import {
  BrandData,
  ChallengeButton,
  DepositButton,
  CashOutButton,
  GuestDemoButton,
  Panel,
} from './styled'
import { t } from 'ttag'
import { IPlatformMenu } from '../../reducers/container'
import { filter, find, isArray, orderBy } from 'lodash'
import { actionSetShowChallenge } from '../../actions/container'
import { actionShowModal, ModalTypes } from '../../actions/modal'
import { actionAddMessage } from '../../actions/messages'
import {
  actionShowNotification,
  NotificationTypes,
} from '../../actions/notifications'

interface IHeaderProps {
  data: IRegistry
  signedIn: boolean
  userInfo: any
  trades: any
  challengeId: any
  actionGuestDemoRequest: () => void
  actionSetShowChallenge: (data: boolean) => void
  showTopMenu: boolean
  actionShowModal: any
  actionShowNotification: any
  topMenuItems: IPlatformMenu[]
  activeWallet: any
  setShowMenuContent: ({ show, link }: { show: boolean; link: string }) => void
  setShowOtherPlatform: ({
    show,
    link,
  }: {
    show: boolean
    link: string
  }) => void
}

const Header = (props: IHeaderProps) => {
  const {
    data,
    signedIn,
    trades,
    showTopMenu,
    topMenuItems,
    activeWallet,
    actionShowModal,
    setShowMenuContent,
    setShowOtherPlatform,
    actionGuestDemoRequest,
    actionSetShowChallenge,
  } = props
  const { partnerConfig, alias } = data

  const {
    allowDemo,
    logoUrl,
    depositLink,
    depositButton,
    defaultTheme,
    logoUrlPrimary,
    logoUrlSecondary,
    challengesEnabled,
  } = partnerConfig
  const guestDemoBtn = allowDemo && !signedIn

  const menuItems = useCallback(() => {
    return orderBy(
      filter(topMenuItems, ({ show }: IPlatformMenu) => !!show).map(
        (item: IPlatformMenu) => {
          return item
        }
      ),
      'order'
    )
  }, [topMenuItems])

  const [activeItem, setActiveItem] = useState<string>(
    find(menuItems(), ({ platform }) => platform === 'fx')?.label || ''
  )

  const [isCashoutButtonReady, setIsCashoutButtonReady] =
    React.useState<boolean>(false)

  const depositBtn = isArray(depositButton) ? depositButton[0] : depositButton

  function handleSignInModal() {
    actionShowModal(ModalTypes.SIGN_IN)
  }

  const renderTradeMessage = () => {
    if (trades?.open?.length > 0 && trades?.pending?.length > 0) {
      return t`Please close all open positions or cancel all pending trades before requesting a cashout`
    } else if (trades?.open?.length > 0) {
      return t`Please close all open positions trades before requesting a cashout`
    } else if (trades?.pending?.length > 0) {
      return t`Please cancel all pending trades before requesting a cashout`
    }
  }

  React.useEffect(() => {
    if (props.challengeId === activeWallet.challengeID) {
      setIsCashoutButtonReady(true)
    } else {
      setIsCashoutButtonReady(false)
    }
  }, [props.challengeId, activeWallet.challengeID])

  return (
    <ThemeContextConsumer>
      {(colors: any) => (
        <Panel colors={colors}>
          <BrandData colors={colors}>
            <a className="brand-logo" href={logoUrl}>
              <img
                height="42"
                src={
                  defaultTheme === 'themeSecondary'
                    ? logoUrlSecondary
                    : logoUrlPrimary
                }
                alt={alias}
              />
            </a>
            {showTopMenu &&
              (menuItems() as IPlatformMenu[]).map(
                ({ label, link, iframe, externalLink }) => (
                  <div
                    key={label}
                    className={`menu-item ${
                      activeItem === label ? 'item-active' : ''
                    }`}
                    onClick={() => {
                      activeItem !== label && setActiveItem(label)
                      if (externalLink != undefined && externalLink === 1) {
                        const uniqueTabName = `tab_${new Date().getTime()}`
                        window.open(link, uniqueTabName)
                      } else {
                        setShowOtherPlatform({
                          show: iframe,
                          link,
                        })
                      }
                    }}
                  >
                    <span>{label}</span>
                  </div>
                )
              )}
            {depositBtn?.show &&
              ((depositBtn?.afterLoginOnly && signedIn) ||
                !depositBtn?.afterLoginOnly) && (
                <div className="menu-item">
                  <DepositButton
                    colors={colors}
                    onClick={() =>
                      setShowMenuContent({
                        show: true,
                        link: depositBtn?.link || depositLink,
                      })
                    }
                  >
                    {t`Deposit`}
                  </DepositButton>
                </div>
              )}
            {activeWallet?.challengeFunded && signedIn && (
              <div className="menu-item">
                <CashOutButton
                  colors={colors}
                  disabled={props.userInfo.frozen || !isCashoutButtonReady}
                  onClick={() => {
                    if (
                      trades?.open?.length > 0 ||
                      trades?.pending?.length > 0
                    ) {
                      props.actionShowNotification(NotificationTypes.GLOBAL, {
                        success: false,
                        message: renderTradeMessage(),
                      })
                    } else {
                      if (!props.userInfo.frozen) {
                        actionShowModal(ModalTypes.CASHOUT)
                      }
                    }
                  }}
                >{t`Cash-Out`}</CashOutButton>
              </div>
            )}
            {challengesEnabled && (
              <ChallengeButton
                colors={colors}
                onClick={() => {
                  if (!signedIn) {
                    handleSignInModal()
                  } else {
                    actionSetShowChallenge(true)
                  }
                }}
              >{t`Buy A Challenge`}</ChallengeButton>
            )}
          </BrandData>
          {guestDemoBtn && (
            <GuestDemoButton
              colors={colors}
              onClick={actionGuestDemoRequest}
            >{t`Guest demo`}</GuestDemoButton>
          )}
          <Time colors={colors} />
          <LanguageSelect colors={colors} />
          <Account
            colors={colors}
            setShowOtherPlatform={setShowOtherPlatform}
            setShowMenuContent={setShowMenuContent}
          />
        </Panel>
      )}
    </ThemeContextConsumer>
  )
}

const mapStateToProps = (state: any) => ({
  data: state.registry.data,
  userInfo: state.account.userInfo,
  trades: state.trades,
  signedIn: isLoggedIn(state),
  showTopMenu: state.container.showTopMenu,
  topMenuItems: state.container.topMenuItems,
  activeWallet: state.wallets.activeWallet,
  challengeId: state.wallets.challengeDashboard.state.challengeID,
})

export default connect(mapStateToProps, {
  actionGuestDemoRequest,
  actionSetShowChallenge,
  actionShowModal,
  actionShowNotification,
})(Header)
