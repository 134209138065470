/* eslint-disable react-hooks/exhaustive-deps */
/**
 * This modal is shown only when trade was accepted by backend
 * Allows to cancel trade in some period of time
 */

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  actionCloseNotification,
  NotificationTypes,
} from '../../../actions/notifications'
import { t } from 'ttag'
import { NotificationModal } from './styled'
import { INotificationAlertProps } from './interfaces'

interface INotificationProps {
  notifications: INotificationAlertProps[]
  actionCloseNotification: (id: number) => void
  isMobile: boolean
  colors: any
}

const NOTIFICATION_TIME = 3000

/**
 * Data layer with effect
 * @param notifications
 * @param actionCloseNotification
 */
const useNotification = (
  notifications: any[],
  actionCloseNotification: any
) => {
  const [current, setCurrent] = useState<any | null>(null)
  /**
   * Each time array changes we pick NEW current notification
   */
  useEffect(() => {
    const [currentNotification] = notifications
    if (currentNotification) {
      if (current?.id !== currentNotification.id) {
        setCurrent(currentNotification)
      }
    } else {
      setCurrent(null)
    }
  }, [notifications])
  /**
   * When we pick new notification, start timer
   */
  useEffect(() => {
    if (current) {
      let timer1 = setTimeout(
        () => actionCloseNotification(current.id),
        NOTIFICATION_TIME
      )
      return () => {
        clearTimeout(timer1)
      }
    }
  }, [current])

  return {
    current,
  }
}

const sanitizeMessage = (input: string | undefined): string => {
  if (input) {
    return input.replace(/<br>/gi, '\n')
  }
  return ''
}

/**
 * Render layer
 * @param props
 * @constructor
 */
const NotificationAlert = (props: INotificationProps) => {
  const { current } = useNotification(
    props.notifications,
    props.actionCloseNotification
  )
  if (!current) {
    return null
  }
  const message = current?.props.message
    ? sanitizeMessage(current?.props.message)
    : ''
  if (current.type === NotificationTypes.GLOBAL) {
    return (
      <NotificationModal
        colors={props.colors}
        success={current?.props?.success || false}
        isMobile={props.isMobile}
      >
        <div
          className="closeButton"
          onClick={() => props.actionCloseNotification(current?.id)}
        />
        <span>{message}</span>
      </NotificationModal>
    )
  } else {
    return null
  }
}

const mapStateToProps = (state: any) => ({
  notifications: state.notifications.notifications,
  colors: state.theme,
  isMobile: state.registry.isMobile,
})

export default connect(mapStateToProps, { actionCloseNotification })(
  NotificationAlert
)
